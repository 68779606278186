import React, { useState, createContext } from 'react'
import ZoneChoice from '../ZoneChoice';
import SectorChoice from "../SectorChoice";
import PrestationChoice from "../PrestationPage";
import MediaChoice from "../MediaChoice";
import SelectionNews from "../SelectionNews";
import RegisterPage from '../RegisterPage';
import MailSetup from '../MailSetup';
import AccountSetupPages from '../AccountSetupPages';
import GmailSetup from '../MailSetup/GmailSetup';
import SmtpSetup from '../MailSetup/SmtpSetup';
import NameCampaignSetup from '../NameCampaignSetup';
import EmailScheduler from '../EmailScheduler/EmailScheduler';


// Créer un contexte pour stocker le formulaire et les fonctions pour le mettre à jour


export const CampaignContext = createContext();



export default function MultiStepNewCampaign() {
  //Différentes fonctions pour gérer le données du formulaire

  //Permet d'aller à la page suivante
  const [page, setPage] = useState(0);
  
  const nextPage = () => {
    setPage(page + 1);
  };

  //Permet de changer de page 
  const changePage = (page) => {
    setPage(page);
  };


  //Gestion des données du formulaire

  const [formData, setFormData] = useState({
    prestation:"",
    target:"",
    media:"",
    interest:"",
    sectorChoice: "" 
    
    
  }); 
  

  const PageDisplay = () => {
    {console.log(formData,setFormData, nextPage)}
    switch (page) {
      case 0:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
        <ZoneChoice />
        </CampaignContext.Provider>
        );
        case 1:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
        <NameCampaignSetup/>
        </CampaignContext.Provider>
        );
        case 2:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
        <MediaChoice />
        </CampaignContext.Provider>
        );
        case 3:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
        <SectorChoice />
        </CampaignContext.Provider>
        );
        case 4:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
        <SelectionNews />
        </CampaignContext.Provider>
        );
        case 5:
        return (
        <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>         
        <PrestationChoice />
        </CampaignContext.Provider>
        );
        case 6:
          return (
            <CampaignContext.Provider value={{ formData, setFormData, changePage }}>
              <AccountSetupPages />
            </CampaignContext.Provider>
          );

          //Deux choix différents pour le mail setup
          case 7:
          return (
            <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
              <GmailSetup />
            </CampaignContext.Provider>
          );
          case 8:
          return (
            <CampaignContext.Provider value={{ formData, setFormData, nextPage }}>
              <EmailScheduler />
            </CampaignContext.Provider>
          );






    }
  };

  return (
    <div className="body">{PageDisplay()}</div>
  )
}
