import {Link, Box, Button, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme, styled } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import { redirect, useNavigate } from "react-router-dom";
import { ClassNames } from "@emotion/react";
import backgroundImage from '../../public/background_image_media_side.png';
import React, { useContext , useState} from "react";
import ApplicationContext from '../../context';
import { FormContext } from '../MultiStepForm';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { FormContextLogin } from "../../App";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1565c0',
      },
      '&:hover fieldset': {
        borderColor: '#73b5ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  });


const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            display: "flex",
            justifyContent : "center",
            width: "18rem"
            

        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },        
    }
});





const registerSchema = yup.object().shape({
    Username: yup.string(),
    UserMail: yup.string().email("invalid email"),
    Password: yup.string(),

});

const initialValuesRegister =  {   
    Username: "",
    UserMail: "",
    Password: ""
    
    };



const RegisterPage = () => {

    //import des variables de connexion
    const { account, setaccount, userToken, setUserToken } = useContext(FormContextLogin);

  

    //Check if email is already in database
    let checkMail = ""

    const {formData, setFormData, nextPage} = useContext(FormContext);  
    const UserFormInformation = formData;    

    const navigate = useNavigate();  


    const register = async (values, onSubmitProps, formInformations) => {
        console.log(values)
        console.log(formInformations)
        const registerObject = {
            ...values,
            ...formInformations
          };
          console.log("values")
        console.log(values)
        console.log(registerObject)

       
 
        console.log("register")
        // const savedUserResponse = await fetch("http://localhost:6001/auth/registersql", {
            const savedUserResponse = await fetch("https://papa-moderne.com/api/registersql", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(registerObject),
            redirect: 'follow'
        });

        const savedUser = await savedUserResponse.json();
        onSubmitProps.resetForm();
        console.log("savedUser",savedUser)
        if (savedUser.error === "User already exists") {
            console.log("User already exists")
            console.log(savedUser)
            checkMail = savedUser.error
            return;
        } else {
            console.log("User created")
            setFormData({
                ...formData,                
                UserMail: values.UserMail                
            });
            return savedUser
            nextPage();
            //navigate("/initmail");
        }      
          

    }




    const handleFormSubmit = async (values, onSubmitProps) => {
        console.log("handleFormSubmit")        
        
        const FirstStepVerification = await register(values, onSubmitProps, formData);
        console.log("FirstStepVerification", FirstStepVerification)
        const userToken = FirstStepVerification.token;
        //setUserToken(userToken);
        sessionStorage.setItem("userToken", userToken); 
        sessionStorage.setItem("userMail", values.UserMail); 
        nextPage(); 

       

    };

    
    

    
    return (
        
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesRegister}
            validationSchema={registerSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    
        <img src = {backgroundImage} className="first_background_image" alt = ""/>
        <img src = {backgroundImage} className="second_background_image" alt = ""/>
        

        <ThemeProvider theme={theme}>
        <Box
            display="flex"
            justifyContent="center"
            flexDirection =  'column'
            alignItems="center"
            minHeight="100vh"
        >



        {checkMail === "User already exists" && ( // Condition de rendu
        <Stack sx={{ width: '40%', marginBottom: "1rem" }} spacing={1}>
          <Alert severity="warning">Adresse e-mail déjà existante</Alert>
        </Stack>
      )}
            
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection =  'column'

                sx={{
                    width: 500 ,
                    height: 500 ,
                    backgroundColor: '#d9ebff',                    
                    borderRadius: '32px',
                    
                   
                }}

            >


                    <Typography variant="h4">
                        Créer un compte
                    </Typography>
             
                        <Typography align="center" variant="h7">
                        Inscrivez-vous pour utiliser l’outil et envoyer un nombre illimité de mails 
                            
                        </Typography>
                   
                    <Box
                        sx={{ width: '75%', margin: '0.1rem' }}
                    >
                            <CssTextField
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.Username}
                            name="Username"
                            error={
                            Boolean(touched.Username) && Boolean(errors.Username)
                            }
                            helperText={touched.Username && errors.Username}
                            margin="normal"
                            required
                            fullWidth
                            id="Username"
                            label="Prénom"
                            
                            autoComplete="Username"
                            autoFocus
                           
                            sx={{
                                input: {
                                  
                                //   background: "white",
                                //   border: 2,
                                //   borderColor : "#007aff",
                                //   borderRadius: "15px",
                                  
                                },
                                
                                
                              }}
                            
                        />
                    </Box>
                    


                    <Box
                        sx={{ width: '75%' , margin: '0.1rem'}}
                    >
                                    <CssTextField
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.UserMail}
                            name="UserMail"
                            error={
                            Boolean(touched.UserMail) && Boolean(errors.UserMail)
                            }
                            helperText={touched.UserMail && errors.UserMail}
                            margin="normal"
                            required
                            fullWidth
                            id="UserMail"
                            label="Adresse mail"
                            
                            autoComplete="UserMail"
                           
                           
                            sx={{
                                input: {
                                  
                                  
                                  
                                },
                                
                                
                              }}
                            
                        />
                    </Box>

                    <Box
                        sx={{ width: '75%', margin: '0.1rem' }}
                    >
                        <CssTextField 
                        
                            margin="normal"
                            required
                            fullWidth
                            name="Password"
                            label="Mot de passe"
                            type="password"
                            id="Password"
                            autoComplete="current-password"
                                        
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.Password}
                                       
                                        error={Boolean(touched.Password) && Boolean(errors.Password)}
                                        helperText={touched.Password && errors.Password}

                                        sx={{
                                            input: {
                                              
                                            //   background: "white",
                                            //   border: 2,
                                            //   borderColor : "#007aff",
                                            //   borderRadius: "15px",
                                              
                                              
                                            },
                                            
                                            
                                          }}
                        />
                    </Box>     

                     <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem" }} >Creer un compte</Button>
                    <Typography fontSize="sm" sx={{margin: '1rem 0rem 0rem 0rem' }} >
                        Vous avez deja un compte ? 

                    </Typography>
                    
                    
                    <Link href="/login" >
                    <Typography fontSize="sm" sx={{ textDecoration: 'underline' }}>
                        Connectez vous

                    </Typography>
                    </Link>


            </Box>




            </Box>
                    </ThemeProvider>
                </form>
            )}
            
        </Formik>


     );
};

export default RegisterPage