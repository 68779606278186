import React, { useEffect,useContext  } from 'react'
import SelectButton from './SelectButton';
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import AroundTheWorld from '../../public/undraw_around_the_world_re_rb1p.svg';
import EiffelTower from '../../public/undraw_eiffel_tower_re_e11r.svg';
import Navigation from '../../public/undraw_navigation_re_wxx4.svg';
import { themeApp } from '../../theme_scene/theme';
import { redirect, useNavigate } from "react-router-dom";
import backgroundImage from '../../public/background_image_media_side.png';
import CheckboxLabels from './Checkbox';
import { FormContext } from '../MultiStepForm';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { height } from '@mui/system';


export default function SectorChoice() {
   
  const { formData, setFormData, nextPage } = useContext(FormContext);
  
  const [isSectorChecked, setIsSectorChecked] = React.useState(true);
  const [submitAttempted, setSubmitAttempted] = React.useState(false); // Nouvel état




  const handleNextPage = () => {
    setSubmitAttempted(true); // Mark form as attempted to be submitted
    
    // We make sure to handle cases where formData.sectorChoice or formData.interest is undefined
    const sectorChoiceLength = formData.sectorChoice ? formData.sectorChoice.length : 0;
    const interestLength = formData.interest && formData.interest.selected ? formData.interest.selected.length : 0;
  
    if (sectorChoiceLength !== 0 && interestLength !== 0) {
      nextPage();
    } else {
      setIsSectorChecked(false);
    }
  };
  

    return (
        <ThemeProvider theme={themeApp}>

            <img src = {backgroundImage} className="choice_background_image" alt = ""/>
            <ColumnFlexBox alignItems={"center"}>
            {submitAttempted && !isSectorChecked && (
    <Stack sx={{ width: '40%'}} spacing={1}>
      <Alert severity="warning">Veuillez sélectionner au moins un secteur et un média</Alert>
    </Stack>
  )}

                <Typography variant="blueTitle" width={"45rem"} marginTop="1rem">ENVOYEZ DES MAILS PERSONNALISÉS AUX MÉDIAS POUR DÉVELOPPER VOTRE MARQUE</Typography>
                <Typography variant="blackSubtitle" marginTop="1.5rem">Communiquez dans votre région </Typography>
                <ColumnFlexBox>
                <RowCenterFlexBox marginTop={"2rem"}>
                <Box  display={"flex"} alignItems={"center"} >
                <Typography align="center" variant="blackSubtitle" marginRight={"1rem"}>VOTRE SECTEUR :  </Typography>

                
                </Box>
                
                <SelectButton />

                </RowCenterFlexBox>

                <RowCenterFlexBox marginTop={"2rem"}>

                <ColumnFlexBox  display={"flex"} alignItems={"center"} >
                <Typography align="center" variant="blackSubtitle" marginRight={"1rem"}>VOUS PARLEZ DE :   </Typography>
                <Typography fontSize={"1rem"} align="center" variant="blackSubtitle" marginRight={"1rem"}>Plusieurs choix possibles </Typography>
                </ColumnFlexBox>
                
                <CheckboxLabels  />
                </RowCenterFlexBox>
               </ColumnFlexBox>
               <RowCenterFlexBox marginTop={"1rem"}>
               <ConnectButton variant="contained" sx={{  width: "10rem" }} onClick= {handleNextPage}> SUIVANT</ConnectButton>
               </RowCenterFlexBox>

            </ColumnFlexBox>
            


        </ThemeProvider>
    )
}
