import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { FormContextLogin } from '../App.js';

export const PrivateRoute = () => {
  const { account, setaccount, userToken, setUserToken } = useContext(FormContextLogin);
  const [isAuthenticated, setIsAuthenticated] = useState(null); // initialisation de l'état à null pour indiquer que la vérification d'authentification est en cours

  //get token from localstorage
  const token = sessionStorage.getItem("userToken");
  console.log("tokenstorage",token)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch("http://localhost:6001/auth/isLoggedIn", {
          const response = await fetch("https://papa-moderne.com/api/isLoggedIn", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({"token" : token}),
        });

        const data = await response.json();
        console.log("response",data)
        setIsAuthenticated(data.isAuthenticated);
        const isAuth = data.isAuthenticated
      if (isAuth){
        let user = data.user
        console.log("user",user)
        await sessionStorage.setItem("userMail", user);
      }

      } catch (error) {
        console.error(error);
        setIsAuthenticated(false); // en cas d'erreur, on considère que l'utilisateur n'est pas authentifié
      }
    };

    fetchData();
  }, [token]); // ajout de userToken dans la liste des dépendances pour que l'effet se déclenche à chaque changement de token

  if (isAuthenticated === null) {
    return null; // ou tout autre élément d'attente (spinner, etc.)
  }

  // suppression de la fonction display(), qui n'a pas de sens ici car elle appelle fetchData() (qui est déjà appelé dans useEffect) et ne retourne rien

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />; // retourne soit le composant Outlet si l'utilisateur est authentifié, soit redirige vers la page de login sinon
};
