import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { padding } from "@mui/system";
export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1565c0',
      },
      '&:hover fieldset': {
        borderColor: '#73b5ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  });

export const themeApp = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        blueTitle: {
          color : "#428BC1",
          fontFamily : "Segoe UI",
          fontWeight: "500", 
          fontSize: "2rem"

        },
        blackSubtitle: {          
          fontFamily : "Segoe UI",
          fontWeight: "500", 
          fontSize: "1.4rem"

        },
        forMore: {
            color :"#73b5ff",
            textAlign: "center"
        },
        accroche : {
          marginTop: "1rem",
          fontSize: "1rem",
          color : "grey" 
         
        },
        title : {
          fontSize: '1.7rem',          
            fontWeight: "bold",
            width : "15rem",
            padding : "1rem 1rem 1rem 0rem"

        },
        description : {
          width : "17rem",
          fontSize: '0.8rem',
          color : '#B7B3B3'

        },
        descUtilisateur : {
          width : "8rem",
          fontSize: '0.8rem', 
          lineHeight : '1.1rem',
          fontStyle: 'italic',
          margin : '1rem'

        }, 
        titleUtilisateur : {
          fontSize: '0.9rem',
          padding : "0.1rem 0.1rem 0.1rem 1rem"
        },
        
        jobUtilisateur : {
          fontSize: '0.6rem',
          color : '#787777',
          padding : "0rem 0rem 0rem 1rem"
          
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            display: "flex",
            justifyContent : "center",
            width: "18rem"
            

        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },        
    }
});
