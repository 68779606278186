import React, { useEffect, useContext }  from 'react'
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import {BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import AroundTheWorld from '../../public/undraw_around_the_world_re_rb1p.svg';
import EiffelTower from '../../public/undraw_eiffel_tower_re_e11r.svg';
import Navigation from '../../public/undraw_navigation_re_wxx4.svg';
import { themeApp } from '../../theme_scene/theme';
import { redirect, useNavigate } from "react-router-dom";
import backgroundImage from '../../public/background_image_media_side.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { height } from '@mui/system';
import { FormContext } from '../MultiStepForm';

const HoverBox = styled(BlueBox)`
  &:hover {
    animation: hoverAnimation 0.5s;
  }
  
  @keyframes hoverAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;


function PrestationChoice() {
  //import form functions


const {formData, setFormData, nextPage} = useContext(FormContext);
  
  
  //Gestion redirection vers nouvelle page
  const handleNextPage = (event) => {  
    const id = event.currentTarget.getAttribute('data-value');
    setFormData({ ...formData, prestation: id});
    nextPage();
  };

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out-back',
        });
      }, []);

     
  return (
    <ThemeProvider theme={themeApp}>
        <ColumnFlexBox alignItems={"center"}>
        <img src = {backgroundImage} className="choice_background_image" alt = ""/>
        <Typography variant="blueTitle" width={"45rem"} marginTop="1rem">ENVOYEZ DES MAILS PERSONNALISÉS AUX MÉDIAS POUR DÉVELOPPER VOTRE MARQUE</Typography>
        <Typography variant="blackSubtitle" marginTop="3rem">Choix de la prestation </Typography>
        <RowCenterFlexBox marginTop={"2rem"}>
        <HoverBox width={"13rem" } height={"20rem"} marginTop="2.5rem"  margin={"1rem"} data-value="1" onClick={handleNextPage} data-aos="flip-right">
          <Typography variant="blackSubtitle" textAlign = "center" marginTop="2rem">Je gère seul ma campagne</Typography>
          <img src={Navigation} className="ChoiceFirstImage" alt="" />
        </HoverBox>
        <HoverBox width={"13rem" } height={"20rem"} marginTop="2.5rem"  margin={"1rem"} data-value="1" onClick={handleNextPage} data-aos="flip-right">
          <Typography variant="blackSubtitle" textAlign = "center" marginTop="2rem">J’ai besoin de modèles de mails, CP et DP</Typography>
          <img src={EiffelTower} className="ChoiceFirstImage" alt="" />
        </HoverBox >
        <HoverBox width={"13rem" } height={"20rem"} marginTop="2.5rem" margin={"1rem"} data-value="1" onClick={handleNextPage} data-aos="flip-right">
          <Typography variant="blackSubtitle" textAlign = "center" marginTop="2rem">Je souhaite déléguer la totalité de ma campagne</Typography>
          <img src={AroundTheWorld} className="ChoiceFirstImage" alt="" />
        </HoverBox>
        </RowCenterFlexBox>
        </ColumnFlexBox>
    </ThemeProvider>
    
  )
}

export default PrestationChoice