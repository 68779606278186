import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import backgroundImage from '../../public/background_image_media_side.png';
import { redirect, useNavigate } from "react-router-dom";
import { FormContextLogin } from "../../App";
import React, { useContext, useState } from "react";
import { FormContext } from '../MultiStepForm';




const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#1565c0',
        },
        '&:hover fieldset': {
            borderColor: '#73b5ff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#1565c0',
        },
    },
});


const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer",
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;",
            display: "flex",
            justifyContent: "center",
            width: "18rem"


        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },
    }
});

const nameCampaignSchema = yup.object().shape({
    nameCampaign: yup.string()
});


const initialValuesLogin = {
    nameCampaign: ""
};


const NameCampaignSetup = () => {
    const navigate = useNavigate();
    const { formData, setFormData, nextPage } = useContext(FormContext);

    //import des variables de connexion
    const { account, setaccount, userToken, setUserToken } = useContext(FormContextLogin);


    const handleFormSubmit = async (values, onSubmitProps) => {
        setFormData({ ...formData, nameCampaign: values.nameCampaign })
        nextPage()

    };


    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesLogin}
            validationSchema={nameCampaignSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <img src={backgroundImage} className="first_background_image" alt="" />
                    <img src={backgroundImage} className="second_background_image" alt="" />

                    <ThemeProvider theme={theme}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection='column'

                                sx={{
                                    width: 500,
                                    height: 500,
                                    backgroundColor: '#d9ebff',

                                    borderRadius: '32px',


                                }}>
                                <Typography variant="h4">
                                    Nommez votre campagne
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection='column'
                                    sx={{ m: 1 }}
                                >
                                </Box>
                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.nameCampaign}
                                        name="nameCampaign"
                                        error={
                                            Boolean(touched.nameCampaign) && Boolean(errors.nameCampaign)
                                        }
                                        helperText={touched.nameCampaign && errors.nameCampaign}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="nameCampaign"
                                        label="Nom de la campagne"
                                        autoComplete="nameCampaign"
                                        autoFocus
                                    />
                                </Box>
                                <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem' }} > SUIVANT</Button>
                            </Box>
                        </Box>
                    </ThemeProvider>
                </form>
            )}
        </Formik>


    );
};

export default NameCampaignSetup