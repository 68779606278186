import React, { useState, createContext, useEffect } from 'react'
import ZoneChoice from '../ZoneChoice';
import SectorChoice from "../SectorChoice";
import PrestationChoice from "../PrestationPage";
import MediaChoice from "../MediaChoice";
import SelectionNews from "../SelectionNews";
import RegisterPage from '../RegisterPage';
import MailSetup from '../MailSetup';
import AccountSetupPages from '../AccountSetupPages';
import GmailSetup from '../MailSetup/GmailSetup';
import SmtpSetup from '../MailSetup/SmtpSetup';
import NameCampaignSetup from '../NameCampaignSetup';
import EmailScheduler from '../EmailScheduler/EmailScheduler';

console.log("SessionStorage :", sessionStorage)
const userToken = sessionStorage.getItem('userToken');


// Créer un contexte pour stocker le formulaire et les fonctions pour le mettre à jour
export const FormContext = createContext();



export default function MultiStepForm() {


  //Permet d'aller à la page suivante
  const [page, setPage] = useState(0);

  const nextPage = () => {
    setPage(page + 1);
  };

  //Permet de changer de page 
  const changePage = (page) => {
    setPage(page);
  };


  //Gestion des données du formulaire
  const [formData, setFormData] = useState({
    prestation: "",
    target: "",
    media: "",
    interest: "",
    sectorChoice: ""


  });


  const PageDisplay = () => {
    const userToken = sessionStorage.getItem('userToken');
    console.log("SessionStorage :", sessionStorage)
    console.log("userToken :", userToken)

    const addCampaign = async () => {
      const UserMail = sessionStorage.getItem('userMail');
      console.log("UserMail :", UserMail)
      formData.UserMail = UserMail
      console.log("formData :", formData)
      const savedUserResponse = await fetch("https://papa-moderne.com/api/addNewCampaign", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
        redirect: 'follow'
      });
    }

    { console.log(formData) }
    switch (page) {
      case 0:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <ZoneChoice />
          </FormContext.Provider>
        );
      case 1:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <NameCampaignSetup />
          </FormContext.Provider>
        );
      case 2:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <MediaChoice />
          </FormContext.Provider>
        );
      case 3:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <SectorChoice />
          </FormContext.Provider>
        );
      case 4:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <SelectionNews />
          </FormContext.Provider>
        );
      case 5:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <PrestationChoice />
          </FormContext.Provider>
        );
        case 6:
          if (userToken === null) {
            console.log("ok")
            return (
              <FormContext.Provider value={{ formData, setFormData, nextPage }}>
                <RegisterPage />
              </FormContext.Provider>
            );
          } else {
            addCampaign();
            nextPage();
          }
          break;
      case 7:
        return (
          <FormContext.Provider value={{ formData, setFormData, changePage }}>
            <AccountSetupPages />
          </FormContext.Provider>
        );

      //Deux choix différents pour le mail setup
      case 8:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <GmailSetup />
          </FormContext.Provider>
        );
      case 9:
        return (
          <FormContext.Provider value={{ formData, setFormData, nextPage }}>
            <EmailScheduler />
          </FormContext.Provider>
        );
    }
  };

  return (
    <div className="body">{PageDisplay()}</div>
  )
}
