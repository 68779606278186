import React, { useEffect, useContext } from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormContext } from '../MultiStepForm';




  //setFormData({ ...formData, regionChoice: event.target.closest('.MuiBox-root').id})
  export default function CheckboxLabels({ isMediaChecked, setIsMediaChecked }) {
  const { formData, setFormData, nextPage } = useContext(FormContext);

// Dans votre fichier Checkbox.jsx
const handleCheckboxChange = (event) => {
  var label = event.target.value;
  var checked = event.target.checked;

  setFormData((prevData) => {
    const selected = prevData.media ? [...prevData.media.selected] : [];
    if (checked) {
      selected.push(label);
    } else {
      const index = selected.indexOf(label);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    }
    // Mettez à jour l'état de isMediaChecked ici, en vérifiant si le tableau selected est vide ou non.
    setIsMediaChecked(selected.length > 0);
    return {
      ...prevData,
      media: {
        selected
      }
    };
  });
};



  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox  value='100' />} onChange={handleCheckboxChange} label="Télévision" />
      <FormControlLabel control={<Checkbox value='110' />} onChange={handleCheckboxChange} label="Presse" />
      <FormControlLabel control={<Checkbox value='120' />} onChange={handleCheckboxChange} label="Presse en ligne" />
      <FormControlLabel control={<Checkbox value='130' />} onChange={handleCheckboxChange} label="Podcasts" />
      <FormControlLabel control={<Checkbox value='140' />} onChange={handleCheckboxChange} label="Radio" />
    </FormGroup>
  );
}