import GmailSetup from './GmailSetup';
import SmtpSetup from './SmtpSetup'
import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import ApplicationContext from '../../context';

function MailSetup(props) {
  const {account, setaccount} = useContext(ApplicationContext)
 
  
  console.log(account)
  console.log(setaccount)
  // console.log(setUser)
  console.log(props)
  console.log("ok")
  const { typeMail } = useParams();
  
  const userGmail = props.SideMail
  switch(typeMail){
    case 'google':      
      
      return <GmailSetup SideMail={userGmail} />

    case "Yahoo":
      return <p>Yahoo</p>
      
         
      case "SMTP":
        return <SmtpSetup SideMail={userGmail}/>



}
}

export default MailSetup;
