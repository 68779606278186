import React, { useEffect, useContext, useState } from 'react'
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import { themeApp } from '../../theme_scene/theme';
import { height } from '@mui/system';
import { FormContext } from '../MultiStepForm';

//Création d'une fonction qui récupère l'ensemble des leads qui correspondent aux critères de l'utilisateur

const getAllLead = async (formInformations) => {
  const formattedData = {
    sectorChoice: formInformations.sectorChoice,
    interest: formInformations.interest.selected,
    media : formInformations.media.selected,
    target : formInformations.target,
  }
  console.log(formattedData)

  // const response = await fetch('http://localhost:6001/auth/getLeads', {
  const response = await fetch('https://papa-moderne.com/api/getLeads', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formattedData),
  });
  const data = await response.json();
  console.log(data)
  return data
}

export default function SelectionNews() {
  const { formData, setFormData, nextPage } = useContext(FormContext);
  console.log(formData)
  console.log(formData.interest.selected)
  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const result = await getAllLead(formData)
      const emails = result.emails
      console.log(emails)
      //On ajoute les emails dans le formData
      setFormData(prevFormData => ({ ...prevFormData, leads: emails }));
      const count = result.count
      console.log(count)
      setResultCount(count);
    }
    fetchData();
  }, []); // Run this effect only once, when the component mounts

  const handleNextPage = () => {
    // Navigate to the next page
    nextPage()  
  };


  return (
    <ThemeProvider theme={themeApp}>
      <ColumnFlexBox alignItems={"center"} justifyContent={"center"} marginTop="2rem">
        <BlueBox width={"45rem"} height={"30rem"}>
          <ColumnFlexBox alignItems={"center"} justifyContent={"center"} >
            <Typography align="center" variant="blackSubtitle"  fontSize="2rem"> Bonne nouvelle   </Typography>
            <Typography align="center" variant="blackSubtitle" marginTop={"1rem"} fontSize="1rem" fontStyle={"1px"}> {resultCount} </Typography>
            <ConnectButton variant="contained"  sx={{  width: "15rem", height: "3.5rem", marginTop: "3rem"} } onClick= {handleNextPage} >Je rédige mon mail</ConnectButton>
          </ColumnFlexBox>
        </BlueBox>
      </ColumnFlexBox>
    </ThemeProvider>
  )
}
