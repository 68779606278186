import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { ColumnFlexBox } from '../../theme_scene/custom_component';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faEnvelope, faUtensils } from '@fortawesome/free-solid-svg-icons';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FormContext } from '../MultiStepForm';
import { ServiceBox } from '../../theme_scene/custom_component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { handleAddVariable } from './SequenceScheduler';
// import {selectedStep, sequenceSchedulers, setSequenceSchedulers} from './SequenceScheduler';

const names = [
    'Nom',
    'Prénom',
    'Email',
    'Téléphone',
  ];

export default function SelectVariables(props) {


    console.log("props", props)
    const [personName, setPersonName] = React.useState([]);
    const handleChangeMultiple = (event) => {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setPersonName(value);
    };


    //create hook to display select variables
    var [display, setDisplay] = React.useState(false);    

    const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState('');
  
    const handleChange = (event) => {
        console.log(props)
        console.log(props)
            //Import des fonctions de SequenceScheduler
        const selectedStep = props.mySelectedStep;
        const sequenceSchedulers = props.mySequenceSchedulers;
        const setSequenceSchedulers = props.mySetSequenceSchedulers;
        console.log("event", event)
        console.log("event.target.value", event.target.value)
        // console.log("selectedStep",selectedStep)
        // console.log("sequenceSchedulers",sequenceSchedulers)
        // console.log("setSequenceSchedulers",setSequenceSchedulers)

        handleAddVariable(event, selectedStep, sequenceSchedulers, setSequenceSchedulers);
        handleClose();
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
      }
    };
  

  return (
   <ColumnFlexBox  >
   <Button onClick={handleClickOpen} style={{maxWidth: '16rem', maxHeight: '2.5rem', minWidth: '14rem', minHeight: '2rem'}}>Ajouter une variable</Button>
   <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Choisissez une variable</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Button onClick={handleChange} value="{{Nom}}">Nom</Button>
          <Button onClick={handleChange} value="{{Prénom}}">Prénom</Button>
            <Button onClick={handleChange} value="{{Téléphone}}">Téléphone</Button>
            <Button onClick={handleChange} value="{{Entreprise}}">Entreprise</Button>
          </Box>
        </DialogContent>
      </Dialog>    
    </ColumnFlexBox>
  );
}
