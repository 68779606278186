import React, { useEffect, useContext } from 'react';
import { Typography, ThemeProvider } from '@mui/material';
import { BlueBox, RowCenterFlexBox, ColumnFlexBox } from '../../theme_scene/custom_component';
import AroundTheWorld from '../../public/undraw_around_the_world_re_rb1p.svg';
import EiffelTower from '../../public/undraw_eiffel_tower_re_e11r.svg';
import Navigation from '../../public/undraw_navigation_re_wxx4.svg';
import { themeApp } from '../../theme_scene/theme';
import backgroundImage from '../../public/background_image_media_side.png';
import { FormContext } from '../MultiStepForm';

function ZoneChoice() {
  const { formData, setFormData, nextPage } = useContext(FormContext);

  useEffect(() => {
    const choiceElements = Array.from(document.querySelectorAll('.choice-element'));
    choiceElements.forEach((element) => {
      element.addEventListener('mouseover', handleMouseOver);
      element.addEventListener('mouseout', handleMouseOut);
    });

    return () => {
      choiceElements.forEach((element) => {
        element.removeEventListener('mouseover', handleMouseOver);
        element.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, []);

  const handleNextPage = (id) => {
    setFormData({ ...formData, target: id });
    nextPage();
  };

  const handleMouseOver = (event) => {
    const element = event.currentTarget;
    element.style.transform = 'scale(1.1)';
  };

  const handleMouseOut = (event) => {
    const element = event.currentTarget;
    element.style.transform = 'scale(1)';
  };

  return (
    <ThemeProvider theme={themeApp}>
      <ColumnFlexBox alignItems="center">
        <img src={backgroundImage} className="choice_background_image" alt="" />
        <Typography variant="blueTitle" width="45rem" marginTop="1rem">
          ENVOYEZ DES MAILS PERSONNALISÉS AUX MÉDIAS POUR DÉVELOPPER VOTRE MARQUE
        </Typography>
        <Typography variant="blackSubtitle" marginTop="3rem">
          Rédigez votre mail, on s’occupe du reste !
        </Typography>
        <RowCenterFlexBox marginTop="2rem">
          <BlueBox
            onClick={() => handleNextPage(1)}
            className="choice-element"
            width="13rem"
            height="20rem"
            marginTop="2.5rem"
            margin="1rem"
          >
            <Typography variant="blackSubtitle" textAlign="center" marginTop="2rem">
              Je souhaite communiquer dans ma région
            </Typography>
            <img src={Navigation} className="ChoiceFirstImage" alt="" />
          </BlueBox>
          <BlueBox
            onClick={() => handleNextPage(1)}
            className="choice-element"
            width="13rem"
            height="20rem"
            marginTop="2.5rem"
            margin="1rem"
          >
            <Typography variant="blackSubtitle" textAlign="center" marginTop="2rem">
              Je souhaite communiquer en France
            </Typography>
            <img src={EiffelTower} className="ChoiceFirstImage" alt="" />
          </BlueBox>
          <BlueBox
            onClick={() => handleNextPage(1)}
            className="choice-element"
            width="13rem"
            height="20rem"
            marginTop="2.5rem"
margin="1rem"
>
<Typography variant="blackSubtitle" textAlign="center" marginTop="2rem">
Je souhaite communiquer à l’international
</Typography>
<img src={AroundTheWorld} className="ChoiceFirstImage" alt="" />
</BlueBox>
</RowCenterFlexBox>
</ColumnFlexBox>
</ThemeProvider>
);
}

export default ZoneChoice;
           
