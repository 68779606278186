import React, { useEffect, useContext } from 'react'
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import AroundTheWorld from '../../public/undraw_around_the_world_re_rb1p.svg';
import EiffelTower from '../../public/undraw_eiffel_tower_re_e11r.svg';
import Navigation from '../../public/undraw_navigation_re_wxx4.svg';
import { themeApp } from '../../theme_scene/theme';
import { redirect, useNavigate } from "react-router-dom";
import backgroundImage from '../../public/background_image_media_side.png';
import CheckboxLabels from './Checkbox';
import { FormContext } from '../MultiStepForm';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { height } from '@mui/system';


export default function SectorChoice() {

  //Import des fonctions pour gérer les données formulaire 
  const { formData, setFormData, nextPage } = useContext(FormContext);

  //create a hook that check if one media is checked
  const [isMediaChecked, setIsMediaChecked] = React.useState(true);

  const [submitAttempted, setSubmitAttempted] = React.useState(false); // Nouvel état


  //Gestion redirection vers nouvelle page
  const handleNextPage = () => {
    setSubmitAttempted(true); // Mettre submitAttempted à vrai lors de la soumission
    if (formData.media && formData.media.selected.length > 0) {
      nextPage();
    } else {
      setIsMediaChecked(false);
    }
  };

  return (
    <ThemeProvider theme={themeApp}>
      <img src={backgroundImage} className="choice_background_image" alt="" />
      <ColumnFlexBox alignItems={"center"}>
      {submitAttempted && !isMediaChecked && ( // Condition de rendu mise à jour
        <Stack sx={{ width: '40%', marginTop:"1.5rem" }} spacing={1}>
          <Alert severity="warning">Veuillez sélectionner au moins un média</Alert>
        </Stack>
      )}


        <Typography variant="blueTitle" width={"45rem"} marginTop="1rem">ENVOYEZ DES MAILS PERSONNALISÉS AUX MÉDIAS POUR DÉVELOPPER VOTRE MARQUE</Typography>
        <Typography variant="blackSubtitle" marginTop="1.5rem">Communiquez dans votre région </Typography>
        <ColumnFlexBox>
          <RowCenterFlexBox marginTop={"2rem"}>
            <Box display={"flex"} alignItems={"center"} >



            </Box>



          </RowCenterFlexBox>

          <RowCenterFlexBox marginTop={"2rem"}>

            <ColumnFlexBox display={"flex"} alignItems={"center"} >
              <Typography align="center" variant="blackSubtitle" marginRight={"1rem"}>LE TYPE DE MEDIA :   </Typography>

            </ColumnFlexBox>

            <CheckboxLabels isMediaChecked={isMediaChecked} setIsMediaChecked={setIsMediaChecked} />
          </RowCenterFlexBox>
        </ColumnFlexBox>
        <RowCenterFlexBox marginTop={"3rem"}>
          <ConnectButton variant="contained" sx={{ width: "10rem" }} onClick={handleNextPage}>SUIVANT</ConnectButton>
        </RowCenterFlexBox>

      </ColumnFlexBox>



    </ThemeProvider>
  )
}
