import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme } from '@mui/material/styles';
import { Drawer, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { blue } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Import the icon
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import EmailScheduler from '../EmailScheduler/EmailScheduler';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';
import backgroundImage from '../../public/background_image_media_side.png';
import { themeApp } from '../../theme_scene/theme';
import { Box, Button, Typography, ThemeProvider } from "@mui/material";
import { styled } from '@mui/system';
import { SwipeableDrawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SequenceSchedulerVisualizer from '../EmailScheduler/SequenceSchedulerVisualizer';
import { BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';



const theme = createTheme({
  typography: {
    body1: {
      color: '#212B27'
    },
    h4: {
      margin: "0.5rem",
      color: "#212B27",
      fontFamily: "revert-layer",
      fontWeight: "bold"

    },
    h7: {
      margin: "0.5rem",
      color: "#32403B",
      fontFamily: "sans-serif;",
      display: "flex",
      justifyContent: "center",
      width: "18rem"


    },
    button: {
      color: '#73b5ff'
    },
    p: {
      color: 'red'
    },
  }
});

//Gestion de la sidebar 
const drawerWidth = 240;

const DrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
}));

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

export default function Dashboard() {
 


  // Composant ActionCell
  const ActionCell = ({ params, handleAnnuler }) => {
    const objectList = JSON.parse(params.value);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const status = params.row.statut.toLowerCase();
    if (status === 'en cours') {
      return (
        <CloseIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => handleAnnuler(params.row.title)}
        />
      );
    } else {
      return (
        <React.Fragment>
          <VisibilityIcon
            style={{ cursor: "pointer" }}
            onClick={handleOpen}
          />
          <Dialog
            open={open}
            onClose={handleClose}
          >

            <SequenceSchedulerVisualizer data={objectList} /> {/* Vous pouvez passer params.row ou tout autre objet à EmailScheduler */}
          </Dialog>
        </React.Fragment>
      );
    }
  };

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const navigate = useNavigate();




  const [campaigns, setCampaigns] = useState([]);
  const userMail = sessionStorage.getItem("userMail");
  console.log(sessionStorage)

  useEffect(() => {
    async function fetchCampaigns() {
      const response = await fetch(
        // "http://localhost:6001/auth/getCampaignsFromUsers",
        "https://papa-moderne.com/api/getCampaignsFromUsers",
        {
          method: "POST",
          body: JSON.stringify({ userMail: userMail }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const campaignsWithId = data.map((campaign, index) => ({
        ...campaign,
        id: index + 1,
      }));
      setCampaigns(campaignsWithId);
    }
    fetchCampaigns();
  }, [userMail]);

  const columns = [
    {
      field: "username",
      headerName: "Nom d'utilisateur",
      width: 180,
      editable: false,

    },
    {
      field: "title",
      headerName: "Titre de la campagne",
      width: 180,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      editable: false,
    },
    {
      field: "cout",
      headerName: "Coût",
      width: 80,
      editable: false,
      cellClassName: 'center-cell',
    },
    {
      field: "mailUsed",
      headerName: "Mail d'envoi",
      width: 200,
      editable: false,
    },
    {
      field: "statut",
      headerName: "Statut",
      type: "number",
      width: 100,
      editable: false,

      cellClassName: (params) =>
        params.row.statut.toLowerCase() === 'terminée'
          ? 'center-cell completed-status'
          : 'center-cell',
    },
    {
      field: 'actions',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        const status = params.row.statut.toLowerCase();


        if (status === 'en cours') {
          return (
            <CloseIcon
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => handleAnnuler(params.row.title)}
            />
          );
        } else {
          return (

            // <ActionCell params={params} handleAnnuler={handleAnnuler} />
            <ActionCell params={params} handleAnnuler={handleAnnuler} />
          );
        }
      },
    },
  ];



  const handleAnnuler = (title) => {
    console.log(`Annuler campagne ${title}`);
    const userMail = sessionStorage.getItem('userMail');
    // fetch('http://localhost:6001/auth/unscheduleEmail', {
      fetch('https://papa-moderne.com/api/unscheduleEmail', {
      method: 'POST',
      body: JSON.stringify({
        userMail: userMail,
        campaignName: title,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        // Mettre à jour la liste des campagnes affichées
        async function fetchCampaigns() {
          const response = await fetch(
            "https://papa-moderne.com/api/getCampaignsFromUsers",
            // "http://localhost:6001/auth/getCampaignsFromUsers",
            {
              method: "POST",
              body: JSON.stringify({ userMail: userMail }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          console.log(data);
          const campaignsWithId = data.map((campaign, index) => ({
            ...campaign,
            id: index + 1,
          }));
          setCampaigns(campaignsWithId);
        }
        fetchCampaigns();
      })
      .catch((error) => {
        console.error('Erreur :', error);
      });
  };
  const handleCreateCampaign = () => {
    navigate('/multiStep');
  }

  //fonction pour logout
  
  const handleLogout = () => {
    // Effacer toutes les données dans le stockage
    sessionStorage.clear();
    localStorage.clear();

    // Rediriger vers la page d'accueil
    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <ColumnFlexBox >

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 0.5, fontSize: '2rem' }} // Increase the icon size
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

        </ColumnFlexBox>

        <DrawerStyled
          variant="temporary"
          open={open}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          <Box sx={{ ...theme.mixins.toolbar }} />
          <List>
            <ListItem>
              <Avatar />
              <Box ml={2}>
                <Typography variant="h18" fontWeight="bold">
                  {userMail}
                </Typography>
              </Box>
            </ListItem>
            <ListItem button onClick={() => navigate('/dashboard')} selected={location.pathname === '/dashboard'}>
              <ListItemIcon sx={{ color: location.pathname === '/dashboard' ? blue[500] : 'inherit' }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            {/* <ListItem button onClick={() => navigate('/parametre')} selected={location.pathname === '/parametre'}> */}
            <ListItem button selected={location.pathname === '/parametre'}>
              <ListItemIcon sx={{ color: location.pathname === '/parametre' ? blue[500] : 'inherit' }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Paramètres" />
            </ListItem>
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%', mb: '5rem' }}>
            <Button style={{ color: "red" }} onClick={handleLogout}>
              Déconnecter
            </Button>
          </Box>
        </DrawerStyled>

        <Content>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            textAlign="center"
            flexDirection="column"
          >
            {console.log("campaigns", campaigns)}

            {/* <img src={backgroundImage} className="choice_background_image" alt="" /> */}
            <Typography variant="h4" width="35rem" marginTop="1rem" marginBottom="1rem">
              Dashboard
            </Typography>
            <Box width="68rem" height="30rem" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                sx={{ margin: '1rem 1rem 1rem 1rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem", alignSelf: 'flex-end' }}
                startIcon={<AddCircleOutlineIcon />} // Add the 'plus' icon
                onClick={handleCreateCampaign}
              >
                Nouveau mail
              </Button>
              <DataGrid
                rows={campaigns}
                columns={columns}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                // getRowClassName={(params) => console.log(params)}
                pagination
                pageSize={10}
                checkboxSelection
                disableSelectionOnClick

              />
            </Box>

          </Box>
        </Content>
      </Box>
    </ThemeProvider>
  );
}