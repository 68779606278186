import React, { useEffect, useContext } from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormContext } from '../MultiStepForm';

export default function CheckboxLabels() {
  const { formData, setFormData, nextPage } = useContext(FormContext);

  const handleCheckboxChange = (event) => {
    const label = event.target.value;
    const checked = event.target.checked;

    setFormData((prevData) => {
      const selected = prevData.interest ? [...prevData.interest.selected] : [];
      if (checked) {
        selected.push(label);
      } else {
        const index = selected.indexOf(label);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }
      return {
        ...prevData,
        interest: {
          ...prevData.interest, 
          selected
        }
      }
    });
  };




  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox  value="10" onChange={handleCheckboxChange} />} label="Votre activité" />
      <FormControlLabel control={<Checkbox value="20" onChange={handleCheckboxChange} />} label="B-to-C" />
      <FormControlLabel control={<Checkbox value="30" onChange={handleCheckboxChange} />} label="B-to-B" />
      <FormControlLabel control={<Checkbox value="40" onChange={handleCheckboxChange} />} label="Création d'entreprise" />
      <FormControlLabel control={<Checkbox value="50" onChange={handleCheckboxChange} />} label="Projet engagé" />
      <FormControlLabel control={<Checkbox value="60" onChange={handleCheckboxChange} />} label="Je vise tous les médias généraux ? " />
    </FormGroup>
  );
}
