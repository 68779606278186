import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import backgroundImage from '../../public/background_image_media_side.png';
import { redirect, useNavigate } from "react-router-dom";
import { FormContextLogin } from "../../App";
import React, { useContext , useState} from "react";



const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1565c0',
      },
      '&:hover fieldset': {
        borderColor: '#73b5ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  });


  const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            display: "flex",
            justifyContent : "center",
            width: "18rem"
            

        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },        
    }
});

const loginSchema = yup.object().shape({
    UserMail: yup.string(),
    Password: yup.string(),

});


const initialValuesLogin = {  
    UserMail: "",
    Password: ""
  

};


const LoginPage = () => {
    const navigate = useNavigate();

    //import des variables de connexion
    const { account, setaccount, userToken, setUserToken } = useContext(FormContextLogin);

    const login = async (values, onSubmitProps) => {
        console.log("on commence")
        // const logedUserResponse = await fetch("http://localhost:6001/auth/loginsql", {
            const logedUserResponse = await fetch("https://papa-moderne.com/api/loginsql", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        });
        console.log(values)

        const logedUser = await logedUserResponse.json();
        console.log(logedUser)
        onSubmitProps.resetForm();
        return logedUser
        
    
    }

    const handleFormSubmit = async (values, onSubmitProps) => {
        const isloggedok = await login(values, onSubmitProps);
        console.log(isloggedok)
        if (isloggedok.isAuthenticated) {
            //S'il est authentifié on récupère l'ensemble des données de l'utilisateur
            setUserToken(isloggedok.token); 
            sessionStorage.setItem("userToken", isloggedok.token); 
            sessionStorage.setItem("userMail", isloggedok.user);      

            navigate("/dashboard");
        }

    };


    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesLogin}
            validationSchema={loginSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
            <form onSubmit={handleSubmit}>
        <img src = {backgroundImage} className="first_background_image" alt = ""/>
        <img src = {backgroundImage} className="second_background_image" alt = ""/>

        <ThemeProvider theme={theme}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection =  'column'

                sx={{
                    width: 500 ,
                    height: 500 ,
                    backgroundColor: '#d9ebff',
                    
                    borderRadius: '32px',
                    
                   
                }}

            >
                    <Typography variant="h4">
                        Se connecter
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection='column'
                        sx={{ m: 1 }}
                       
                    >
                        <Typography  align="center" variant="h7">
                        Connectez-vous pour acceder à votre compte 
                            
                        </Typography>
                       
                    </Box>
                    <Box
                        sx={{ width: '75%', margin: '0.1rem' }}
                    >
                                    <CssTextField
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.UserMail}
                            name="UserMail"
                            error={
                            Boolean(touched.UserMail) && Boolean(errors.UserMail)
                            }
                            helperText={touched.UserMail && errors.UserMail}
                            margin="normal"
                            required
                            fullWidth
                            id="UserMail"
                            label="Adresse Email"
                            
                            autoComplete="UserMail"
                            autoFocus
                            
                            
                        />
                    </Box>

                    <Box
                        sx={{ width: '75%' , margin: '0.1rem'}}
                    >
                        <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            name="Password"
                            label="Password"
                            type="password"
                            id="Password"
                            autoComplete="current-Password"
                                        
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.Password}
                                       
                                        error={Boolean(touched.Password) && Boolean(errors.Password)}
                                        helperText={touched.Password && errors.Password}
                        />
                    </Box>     

                    <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem" }} >SE CONNECTER</Button>

                    <Typography fontSize="sm" sx={{margin: '1rem 0rem 0rem 0rem' }} >
                       Vous n'avez pas de compte ?

                    </Typography>
                    <Link href="/multiStep" >
                    <Typography fontSize="sm" sx={{ textDecoration: 'underline' }}>
                       Incrivez-vous

                    </Typography>
                    </Link>


            </Box>




            </Box>
                    </ThemeProvider>
                </form>
            )}
        </Formik>


     );
};

export default LoginPage