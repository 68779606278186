import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';

export const ConnectButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',    
    borderRadius : '20px',
    lineHeight: 1.5,
    backgroundColor: '#73b5ff',
    borderColor: '#73b5ff',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    }
  });


  
export const RegisterButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    borderRadius : '20px',
    lineHeight: 1.5,
    color: 'B7B3B3',    
    borderColor: '#B7B3B3',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      
      borderColor: '#B7B3B8',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    }
  });

export const greyLine = styled(Box)({
  borderBottom:"2px solid grey",
  width:"9rem",
  marginLeft:"8rem",
})


export const centerBox = styled(Box)({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center"
})

export const ServicesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  [data-aos="fade-up"];

  h1 {
    margin-bottom: 0;
  }

 
`;

export const ButtonBox = styled(Box)`
  display: flex;
  justify-content: end;

  & > button:first-of-type {
    margin: 1rem 1rem 0rem 1rem;
    color: #b7b3b3;
  }

  & > button:last-of-type {
    margin: 1rem 1rem 0rem 0rem;
  }
`;

export const TitleBox = styled(Box)`
  display: flex;
  margin-left: 8rem;
  font-size: 1.6rem;
  margin-top: 4rem;
  width : 41rem;
  

  h1 {
    margin-bot: 1rem;
  }
`;

export const LineBox = styled(Box)`
  border-bottom: 2px solid grey;
  width: 9%;

`;

export const DescriptionBox = styled(Box)`
  display: flex;
  margin-left: 8rem;
  font-size: 0.6rem;
  color: grey;
  margin-top: 1rem;
  width : 30rem;  

  h2 {
    font-weight: normal;
    margin-bottom : 1rem;
  }
`;

export const ColumnFlexBox = styled(Box)`
[data-aos="fade-up"] {
  transition: transform 0.5s ease-out;
}
  display: flex;
  flex-direction: column;
`;
export const RowCenterFlexBox = styled(Box)`

  display: flex;
  alignItems: "center"
`;

export const IconBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "1.2rem",
});

export const Title = styled(Typography)({
  fontWeight: "bold",
});

export const Description = styled(Typography)({
  color: "grey",
});

export const ServiceBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const UserReviewsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10rem 0rem 0rem 0rem;
`;

export const ReviewsTitle = styled(Typography)`
    margin-bottom: 10rem;
`;

export const Divider = styled(Box)`
    border-bottom: 2px solid grey;
    width: 5%;
`;

export const ReviewContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ReviewText = styled(Typography)`
    margin-bottom: 1.5rem;
`;

// export const UserImage = styled(Image)`
//     margin-right: 1rem;
// `;

export const UserTitle = styled(Typography)`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const UserJob = styled(Typography)`
    font-size: 0.875rem;
`;



export const BlueBox = styled(Box)`
    background-color : #d9ebff;
    border-radius : 25px;
    display: flex;
    justify-content: center;   
    align-items: center;
    flex-direction: column;
`;






