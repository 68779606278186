import React, { useEffect } from 'react'
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { themeApp } from '../../theme_scene/theme';
import { ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import MailDrawing from '../../public/mailDrawing.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faEnvelope, faMobile, faTruck } from '@fortawesome/free-solid-svg-icons'
import user from '../../public/user.png';
import { redirect, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';





export default function LandinPage() {

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out-back',
        });
    }, []);

    const navigate = useNavigate();

    const handleRegister = () => {
        // Effacer les données du stockage
        localStorage.clear();

        // Effacer les données de la session
        sessionStorage.clear();

        // Navigate to the home page
        navigate("/multiStep");
    };


    const handleConnection = () => {
        // Navigate to the home page
        navigate("/login");
    };

    const handleNewCLient = () => {
        // Effacer les données du stockage
        localStorage.clear();

        // Effacer les données de la session
        sessionStorage.clear();
        // Navigate to the home page
        navigate("/multiStep");
    };




    return (

        <ThemeProvider theme={themeApp}>

            <ButtonBox>
                <RegisterButton variant="outlined" onClick={handleRegister} >S'inscrire</RegisterButton>
                <ConnectButton variant="contained" onClick={handleConnection}>Se connecter</ConnectButton>
            </ButtonBox>
            <TitleBox data-aos="zoom-out-left">
                <h1>Envoyez vos mails professionnels en un clic !</h1>
            </TitleBox>
            <LineBox sx={{ marginLeft: "8rem" }} />
            <DescriptionBox sx={{ marginBottom: "2rem" }}>
                <h2>Avec Media Linker envoyez un mail personnalisé à une liste de contacts illimitée.</h2>
            </DescriptionBox>
            <ConnectButton variant="contained" sx={{ margin: '2rem 1rem 10rem 8rem', width: "10rem" }} onClick={handleNewCLient}>C'est parti</ConnectButton>
            <Box display="flex" justifyContent="space-around">
                <img src={MailDrawing} className="LandingFirstImage" alt="" data-aos="fade-up" />
                <ColumnFlexBox sx={{ width: '20rem' }}>
                    <h1 >Gestionnaire de mails professionnels</h1>
                    <Box borderBottom="2px solid grey" width="15%" />

                    <Typography variant="accroche" sx={{ marginBottom: "1.4rem" }} >Vous souhaitez envoyer des mails personnalisés à votre base de données ? Vous êtes au bon endroit !</Typography>

                    <Typography variant="forMore">En savoir plus</Typography>
                </ColumnFlexBox>
            </Box>
            <ServicesContainer sx={{ justifyContent: "space-between", marginTop: "10rem", marginBottom: "2rem" }}>
                <h1>Nos Services</h1>
                <LineBox sx={{ marginTop: "1rem" }} />
            </ServicesContainer>
            <Box display="flex" justifyContent="space-around" >
                <ServiceBox>
                    <IconBox data-aos="fade-right">
                        <FontAwesomeIcon size="3x" color="#73b5ff" icon={faEnvelope} data-aos="fade-up" />
                        <ColumnFlexBox sx={{ margin: "1rem" }}>
                            <Title variant="title" sx={{ width: "17rem" }}>Gestion et envoi de mails personnalisés</Title>
                            <Description variant="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eu.
                            </Description>
                        </ColumnFlexBox>
                    </IconBox>
                    <IconBox data-aos="fade-right">
                        <FontAwesomeIcon size="3x" color="#73b5ff" icon={faMobile} data-aos="fade-up" />
                        <ColumnFlexBox sx={{ margin: "1rem" }}>
                            <Title variant="title">Mobile Recharge</Title>
                            <Description variant="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eu.
                            </Description>
                        </ColumnFlexBox>
                    </IconBox>
                </ServiceBox>
                <ServiceBox>
                    <IconBox data-aos="fade-right">
                        <FontAwesomeIcon size="3x" color="#73b5ff" icon={faDatabase} data-aos="fade-up" />
                        <ColumnFlexBox sx={{ margin: "1rem" }}>
                            <Title variant="title">Base de données de journalistes</Title>
                            <Description variant="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eu.
                            </Description>
                        </ColumnFlexBox>
                    </IconBox >
                    <IconBox data-aos="fade-right">
                        <FontAwesomeIcon size="3x" color="#73b5ff" icon={faTruck} data-aos="fade-up" />
                        <ColumnFlexBox sx={{ margin: "1rem" }}>
                            <Title variant="title">Easy and Fast</Title>
                            <Description variant="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eu.
                            </Description>
                        </ColumnFlexBox>
                    </IconBox>
                </ServiceBox>
            </Box>
            <UserReviewsContainer>
                <h1 >Les avis des utilisateurs</h1>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ColumnFlexBox sx={{ m: 4.5 }} data-aos="zoom-in">
                        <ReviewText variant="descUtilisateur">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in aliquet justo. Nulla varius vitae.
                        </ReviewText>
                        <Box display="flex" flexDirection="row">
                            <img src={user} className="userImage" alt="" />
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <UserTitle variant="titleUtilisateur">Dev opter</UserTitle>
                                <UserJob variant="jobUtilisateur">Designer</UserJob>
                            </Box>
                        </Box>
                    </ColumnFlexBox>
                    <ColumnFlexBox sx={{ m: 4.5 }} data-aos="zoom-in">
                        <ReviewText variant="descUtilisateur">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in aliquet justo. Nulla varius vitae.
                        </ReviewText>
                        <Box display="flex" flexDirection="row">
                            <img src={user} className="userImage" alt="" />
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <UserTitle variant="titleUtilisateur">Dev opter</UserTitle>
                                <UserJob variant="jobUtilisateur">Designer</UserJob>
                            </Box>
                        </Box>
                    </ColumnFlexBox>
                    <ColumnFlexBox sx={{ m: 4.5 }} data-aos="zoom-in">
                        <ReviewText variant="descUtilisateur">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis in aliquet justo. Nulla varius vitae.
                        </ReviewText>
                        <Box display="flex" flexDirection="row">
                            <img src={user} className="userImage" alt="" />
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <UserTitle variant="titleUtilisateur">Dev opter</UserTitle>
                                <UserJob variant="jobUtilisateur">Designer</UserJob>
                            </Box>
                        </Box>
                    </ColumnFlexBox>
                </Box>
            </UserReviewsContainer>
            <Box sx={{ backgroundColor: "#73b5ff", height: "20rem", marginTop: "12rem" }} />


        </ThemeProvider>
    )
}
