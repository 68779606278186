import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faEnvelope, faUtensils } from '@fortawesome/free-solid-svg-icons';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FormContext } from '../MultiStepForm';
import { ServiceBox } from '../../theme_scene/custom_component';

export default function SelectButton() {
  const { formData, setFormData, nextPage } = useContext(FormContext);

  //convert event.target.value to string
 
  const handleChange = (event) => {
    console.log(typeof event.target.value)
    setFormData({ ...formData, sectorChoice: event.target.value });
  };

  return (
    <ServiceBox width={'18rem'} justifyContent="center" alignItems="center">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Secteur</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formData.sectorChoice}
          label="Secteur"
          onChange={handleChange}
        >
          <MenuItem value={"10"}>
            {/* <ListItemIcon>
              <FontAwesomeIcon size="1x" color="#73b5ff" icon={faUtensils} />
            </ListItemIcon> */}
            Agroalimentaire
          </MenuItem>
          <MenuItem value={"20"}>Bois / Papier / Carton / Imprimerie</MenuItem>
          <MenuItem value={"30"}>Chimie/ Parachimie</MenuItem>
          <MenuItem value={"40"}>Edition / Communication / Multimédia</MenuItem>
          <MenuItem value={"50"}>Etudes et conseils</MenuItem>
          <MenuItem value={"60"}>Machines et équipements / Automobile</MenuItem>
          <MenuItem value={"70"}>Plastique / Caoutchouc</MenuItem>
          <MenuItem value={"80"}>Textile / Habillement / Chaussure</MenuItem>
          <MenuItem value={"90"}>Banque / Assurance</MenuItem>
          <MenuItem value={"100"}>BTP / Matériaux de construction</MenuItem>
          <MenuItem value={"110"}>Commerce / Négoce / Distribution</MenuItem>
          <MenuItem value={"120"}>Electronique / Electricité</MenuItem>
          <MenuItem value={"130"}>Industrie pharmaceutique</MenuItem>
          <MenuItem value={"140"}>Informatique / Télécoms</MenuItem>
          <MenuItem value={"150"}>Métallurgie / Travail du métal</MenuItem>
          <MenuItem value={"160"}>Services aux entreprise</MenuItem>
          <MenuItem value={"170"}>Transport / Logistique</MenuItem>
        </Select>
      </FormControl>
    </ServiceBox>
  );
}
