import React, { useEffect, useContext, useState } from 'react'
import { InputLabel, Select, MenuItem, Link, Divider, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { SequenceBox, SequenceBoxTitle, SequenceBoxObject, SequenceBoxABTitle } from './ComponentScheduler';
import { RowCenterFlexBox } from '../../theme_scene/custom_component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Switch from '@mui/material/Switch';
import SelectVariables from './selectVariables';
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { compose } from '@mui/system';

//fonction qui permet de gérer l'ajour de données dans le corps du mail
export function handleInputChange(event, selectedStep, sequenceSchedulers, setSequenceSchedulers) {
  const iterateAllStep = sequenceSchedulers.map(s => {
    if (s.numEtape === selectedStep && s.abTestingChecked === false) {

      const updatedSequenceScheduler = { ...s, corpsA: event.target.value };
      { console.log(updatedSequenceScheduler) }
      return updatedSequenceScheduler;
    } else if (s.numEtape === selectedStep && s.enablleabTesting === true) {
      const updatedSequenceScheduler = { ...s, corpsB: event.target.value };
      return updatedSequenceScheduler;
    }
    return s;
  });
  setSequenceSchedulers(iterateAllStep);
}

export function handleAddVariable(event, selectedStep, sequenceSchedulers, setSequenceSchedulers) {
  const iterateAllStep = sequenceSchedulers.map(s => {
    if (s.numEtape === selectedStep && s.abTestingChecked === false) {

      const updatedSequenceScheduler = { ...s, corpsA: s.corpsA + event.target.value };
      //  { console.log(updatedSequenceScheduler)}
      return updatedSequenceScheduler;
    } else if (s.numEtape === selectedStep && s.enablleabTesting === true) {
      const updatedSequenceScheduler = { ...s, corpsB: s.corpsB + event.target.value };
      return updatedSequenceScheduler;
    }
    return s;
  });
  setSequenceSchedulers(iterateAllStep);
}


export default function SequenceScheduler(props) {

  //Gérer les fichiers de Pièce jointe uploadé
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileSelect = (event) => {
    const files = [...event.target.files];
    setSelectedFiles(files);
    console.log("fichiers sélectionnés : ", files);
  
    //On met à jour les données 
    const updatedSequenceSchedulers = sequenceSchedulers.map(s => {
      if (s.numEtape === selectedStep) {
        if(s.abTestingChecked === false) {
          return { ...s, attachmentsA: [...s.attachmentsA, ...files] };
        } else if (s.enablleabTesting === true) {
          return { ...s, attachmentsB: [...s.attachmentsB, ...files]};
        }
      }
      return s;
    });
  
    setSequenceSchedulers(updatedSequenceSchedulers);
    console.log(sequenceSchedulers);
  };

  const handleUpload = async (file) => {
  const formData = new FormData();

  if (!file) {
    console.error('No file selected for upload.');
    return;
  }

  formData.append('files', file);

  formData.append('userMail', 'Aaron');

  for (let key of formData.keys()) {
    console.log(key, formData.getAll(key));
  }

  try {
    // const response = await fetch('http://localhost:6001/auth/storeUserAttachments', {
      const response = await fetch('https://papa-moderne.com/api/storeUserAttachments', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

  
  


  const navigate = useNavigate();
  //On utilise les données du formulaire
  const { formData } = props;

  //On initialise la première étape 
  const [sequenceSchedulers, setSequenceSchedulers] = useState([{ numEtape: 1, abTestingChecked: false, enablleabTesting: false, timeValue: 0, timeUnit: 1, objetA: "", corpsA: "", objetB: "", corpsB: "", attachmentsA: [], attachmentsB: [] }]);

  //Fonction qui permet d'ajouter une nouvelle étape 
  const addSequenceScheduler = () => {

    const lastStep = sequenceSchedulers[sequenceSchedulers.length - 1].numEtape;
    const newSequenceSchedulers = [
      ...sequenceSchedulers,
      { numEtape: lastStep + 1, abTestingChecked: false, enablleabTesting: false, timeValue: 0, timeUnit: 1, objetA: "", corpsA: "", objetB: "", corpsB: "", attachmentsA: [], attachmentsB: [] }
    ];
    setSequenceSchedulers(newSequenceSchedulers);
    console.log(sequenceSchedulers)

  };


  //Fonction qui met à jour l'ensemble des nombres des schedulers
  const UpdatetNumbersSequenceScheduler = (sequenceSchedulers) => {
    const newSequenceSchedulers = sequenceSchedulers.map((s, index) => {
      return {
        ...s,
        numEtape: index + 1
      }
    });
    setSequenceSchedulers(newSequenceSchedulers);
  }

  //On cherche à savoir l'étape sélectionné

  //Classe CSS qui permet de modifier l'apparence de l'étape sélectionné
  const defaultClassName = "StepContainer";
  const selectedClassName = "unselectedStep";


  //gère le changement d'étape sélectionné
  const [selectedStep, setselectedStep] = useState(1)
  const setNewSelectedStep = (event) => {
    const target = event.target;
    // Check if the element is SVG or path and skip function
    if (target.tagName === "svg" || target.tagName === "path") {
      return;
    }
    // console.log(target);
    setselectedStep(parseInt(target.closest('.StepContainer').id));
  }

  


  //fonction pour envoyer le mail 
  async function SendMessage() {
  //On met à jour l'objet schedulers
   const finalScheduler = await handleAttachmentScheduler();
   

    console.log(finalScheduler)
    const stringifiedData = JSON.stringify(finalScheduler);
    //On récupère le tokena afin d'identifier l'utilisateur
    const authMail = sessionStorage.getItem('userMail');
    console.log(sessionStorage)
    console.log('userToken', authMail);
    //Appel vers l'API
    const fetchData = async () => {
      try {
        // const response = await fetch("http://localhost:6001/auth/scheduleEmail", {
          const response = await fetch("https://papa-moderne.com/api/scheduleEmail", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ "userMail": authMail, "data": finalScheduler, 'dataString': stringifiedData, "nameCampaign": formData.nameCampaign }),
        });

        const data = await response.json();
        console.log("response", data);
      } catch (error) {
        console.error(error);
      }
    };

    await fetchData();
    navigate("/dashboard");
  }




  const handleAttachmentScheduler = async () => {
    const scheduler = sequenceSchedulers;
    console.log("handleAttachmentScheduler début", scheduler);
  
    for (let i = 0; i < scheduler.length; i++) {
      let step = scheduler[i];
  
      if (step.attachmentsA && step.attachmentsA.length > 0) {
        let pathsA = [];
        for (let j = 0; j < step.attachmentsA.length; j++) {
          let file = step.attachmentsA[j];
          let getFilePath = await handleUpload(file);
          console.log("getFilePath", getFilePath);
          let filePath = getFilePath.files;
          console.log("filePath", filePath);
          pathsA.push(filePath);
        }
        step.attachmentsA = pathsA;
      }
  
      if (step.attachmentsB && step.attachmentsB.length > 0) {
        let pathsB = [];
        for (let j = 0; j < step.attachmentsB.length; j++) {
          let file = step.attachmentsB[j];
          let getFilePath = await handleUpload(file);
          console.log("getFilePath", getFilePath);
          let filePath = getFilePath.files;
          console.log("filePath", filePath);
          pathsB.push(filePath);
        }
        step.attachmentsB = pathsB;
      }
    }
  
    console.log("handleAttachmentScheduler fini", scheduler);
  
    return scheduler;
  };
  

  return (
    <Box sx={{ display: 'flex', flexDirection: "column", marginLeft: "1rem", marginRight: "1rem" }}>
      <div>
        {/* Box qui contient toute les étapes*/}
        <Box sx={{ width: '100%', overflowX: 'auto', display: 'flex', height: 160 }}>

          {/* On itère parmis l'object sequenceSchduler pour créer chaque Box */}
          {sequenceSchedulers.map((sequencer) => (

            <Box className={`${defaultClassName} ${selectedStep !== sequencer.numEtape ? selectedClassName : ""}`} onClick={setNewSelectedStep} id={sequencer.numEtape}>
              <RowCenterFlexBox>
                <Box>
                  <SequenceBox>


                    <Box position="relative">
                      <Box position="absolute" top="6px" right="0">
                        <FontAwesomeIcon
                          onClick={() => {
                            //Suppression d'une étape
                            const test = sequenceSchedulers.map(s => {

                              //1ère suppression fait en sorte d'enlever le AB Testing
                              if (s.numEtape === sequencer.numEtape && sequencer.enablleabTesting === true) {
                                const updatedSequenceScheduler = { ...s, enablleabTesting: false, abTestingChecked: false };

                                setSequenceSchedulers(sequenceSchedulers);
                                return updatedSequenceScheduler;
                              } else if (s.numEtape === sequencer.numEtape && sequencer.enablleabTesting === false && sequenceSchedulers.length !== 1) {

                                if (sequenceSchedulers.length === selectedStep) {
                                  setselectedStep(selectedStep - 1)

                                }
                                // setselectedStep(1)
                              } else { return s }

                            });
                            const newSequenceSchedulers = test.filter(x => x !== undefined)
                            UpdatetNumbersSequenceScheduler(newSequenceSchedulers)
                          }}

                          size="1x"
                          color="white"
                          icon={faXmark}
                        />
                      </Box>
                      <SequenceBoxTitle>Etape {sequencer.numEtape}</SequenceBoxTitle>


                      <SequenceBoxObject>{sequenceSchedulers[sequencer.numEtape - 1]["abTestingChecked"] === true ? sequenceSchedulers[sequencer.numEtape - 1]["objetB"] : sequenceSchedulers[sequencer.numEtape - 1]["objetA"]}</SequenceBoxObject>
                    </Box>
                  </SequenceBox>

                  {!sequencer.enablleabTesting ? (
                    <SequenceBoxABTitle>
                      <Typography marginLeft={"2.8rem"} fontSize={"0.7rem"} color="white">A/B Testing </Typography>
                      <FontAwesomeIcon
                        onClick={() => {
                          const newSequenceSchedulers = sequenceSchedulers.map(s => {
                            if (s.numEtape === sequencer.numEtape) {
                              return { ...s, enablleabTesting: true };
                            }
                            return s;
                          });
                          setSequenceSchedulers(newSequenceSchedulers);
                        }}
                        size="1x"
                        color="white"
                        icon={faPlus}
                      />
                    </SequenceBoxABTitle>
                  ) : (
                    <SequenceBoxABTitle>
                      A
                      <Switch
                        checked={sequencer.abTestingChecked}
                        onChange={(event) => {
                          const newSequenceSchedulers = sequenceSchedulers.map(s => {
                            if (s.numEtape === sequencer.numEtape) {
                              return { ...s, abTestingChecked: event.target.checked };
                            }
                            return s;
                          });
                          setSequenceSchedulers(newSequenceSchedulers);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      B
                    </SequenceBoxABTitle>
                  )}
                </Box >

                {sequenceSchedulers.length != sequencer.numEtape &&
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: "column", marginLeft: "1rem", marginRight: "1rem" }}>

                    <Typography variant="body1" color="initial" fontSize={"0.5rem"} m={"1rem"}>attendre</Typography>
                    <TextField
                      id="outlined-number"
                      type="number"
                      size="medium"
                      value={sequencer.timeValue}
                      variant="standard"
                      inputProps={{ style: { fontSize: 10, width: 30 } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: 10 } }} // font size of input label
                      onChange={(event) => {
                        const test = sequenceSchedulers.map(s => {
                          if (s.numEtape === sequencer.numEtape) {
                            //only get positive value
                            const positiveValue = event.target.value < 0 ? 0 : event.target.value;
                            const updatedSequenceScheduler = { ...s, timeValue: positiveValue };
                            return updatedSequenceScheduler;
                          }
                          return s;
                        });
                        setSequenceSchedulers(test);


                      }}
                    />
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sequencer.timeUnit}
                      style={{ fontSize: '10px' }}
                      inputProps={{ style: { fontSize: ' 5px', width: 20 } }}
                      onChange={(event) => {
                        const test = sequenceSchedulers.map(s => {
                          if (s.numEtape === sequencer.numEtape) {
                            const updatedSequenceScheduler = { ...s, timeUnit: event.target.value };
                            setSequenceSchedulers(sequenceSchedulers);
                            return updatedSequenceScheduler;
                          } return s;
                        });
                        setSequenceSchedulers(test);

                      }}




                    >
                      <MenuItem value={1}>minutes</MenuItem>
                      <MenuItem value={24 * 60}>jours</MenuItem>
                      <MenuItem value={60}>heures</MenuItem>
                    </Select>




                  </Box>



                }


              </RowCenterFlexBox>
            </Box>
          ))}
        </Box>
        <RowCenterFlexBox>
          <Button onClick={addSequenceScheduler}>Ajouter une étape</Button>
          <Button component="label">
            Ajouter une Pièce-Jointe
            <input type="file" id="myFiles" multiple hidden onChange={handleFileSelect} />
          </Button>
       



          {/* <Button >Ajouter une variable</Button> */}
          {/* Display Select button for variables  */}
          <SelectVariables test="test"
            mySelectedStep={selectedStep}
            mySequenceSchedulers={sequenceSchedulers}
            mySetSequenceSchedulers={setSequenceSchedulers}
          />
        </RowCenterFlexBox>


      </div>


      <TextField id="standard-basic" label="Objet" variant="standard" value={sequenceSchedulers[selectedStep - 1]["abTestingChecked"] === true ? sequenceSchedulers[selectedStep - 1]["objetB"] : sequenceSchedulers[selectedStep - 1]["objetA"]}
        onChange={(event) => {

          const iterateAllStep = sequenceSchedulers.map(s => {
            if (s.numEtape === selectedStep && s.abTestingChecked === false) {
              const updatedSequenceScheduler = { ...s, objetA: event.target.value };
              setSequenceSchedulers(sequenceSchedulers);
              return updatedSequenceScheduler;
            } else if (s.numEtape === selectedStep && s.enablleabTesting === true) {

              // console.log(s)
              const updatedSequenceScheduler = { ...s, objetB: event.target.value };
              setSequenceSchedulers(sequenceSchedulers);
              return updatedSequenceScheduler;
            }

            return s;
          });
          setSequenceSchedulers(iterateAllStep);

        }}

      />
      <TextField
        id="standard-multiline-static"
        label="Corps"
        multiline
        rows={7}
        value={sequenceSchedulers[selectedStep - 1]["abTestingChecked"] === true ? sequenceSchedulers[selectedStep - 1]["corpsB"] : sequenceSchedulers[selectedStep - 1]["corpsA"]}
        variant="standard"
        onChange={(event) => handleInputChange(event, selectedStep, sequenceSchedulers, setSequenceSchedulers)}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button variant="contained" onClick={SendMessage} style={{ width: "8rem", marginTop: "1rem" }}>Envoyer</Button>

      </Box>

      {sequenceSchedulers
  .filter(s => s.numEtape === selectedStep)
  .flatMap(s => 
    s.abTestingChecked === false
      ? s.attachmentsA
      : s.enablleabTesting === true
        ? s.attachmentsB
        : []
  )
  .map((file) => (
    <div key={file.name}>
      Pièce-jointe :  {file.name}
      <span 
        style={{ marginLeft: '5px', color: 'red', cursor: 'pointer' }}
        onClick={() => {
          const updatedSequenceSchedulers = sequenceSchedulers.map(ss => {
            if (ss.numEtape === selectedStep) {
              if(ss.abTestingChecked === false) {
                return { ...ss, attachmentsA: ss.attachmentsA.filter(f => f.name !== file.name) };
              } else if (ss.enablleabTesting === true) {
                return { ...ss, attachmentsB: ss.attachmentsB.filter(f => f.name !== file.name)};
              }
            }
            return ss;
          });
  
          setSequenceSchedulers(updatedSequenceSchedulers);
        }}>
        &#10006;
      </span>
    </div>
  ))
}
    </Box>
  )
}