import { Box, Button, FormControl, Select, InputLabel, MenuItem, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme, styled } from '@mui/material/styles';
import React, { useState,useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationContext from '../../context';
import { FormContext } from '../MultiStepForm';


const CssFormControl = styled(FormControl)({
    '& label.Mui-focused': {
      color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1565c0',
      },
      '&:hover fieldset': {
        borderColor: '#73b5ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  });

const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            fontWeight: "bold"

        },
        h8: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            display: "flex",
            justifyContent : "center",
            width: "18rem"
            

        },
        h1: {
            margin: "1rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            fontWeight : "bold",      
           
            width: "18rem"
            

        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },        
    },
    textField: {
        borderRadius : 50

    }
});



const initialValuesLogin = {
    email: "",
    password: ""


};




const AccountSetupPages = () => {

    const {formData, setFormData, changePage} = useContext(FormContext);  
    
   

const [selectedAccount, setSelectedAccount] = useState('');


  const handleSelectChange = (event) => {
    event.preventDefault();
    setSelectedAccount(event.target.value);
    console.log(event.target.value)
  };

  const handleNextButtonClick = () => {
    console.log(selectedAccount)
    changePage( parseInt(selectedAccount))
    
   
    //type="SMTP" SideMail="bobby@example.com"
    //navigate(`/mail/${selectedAccount}`);
  };
    

    return (
                    <ThemeProvider theme={theme}>
                            <Box
                            position={"absolute"}
                            top = "0"
                            left = "0"
                         
                        >
                            <Typography variant="h4" marginLeft={"1rem"}>
                                Bienvenue !
                            </Typography>
                            <Typography variant="h8" marginLeft={"1rem"}>
                                Encore quelques étapes avant de commencer à envoyer vos mails !
                            </Typography>


                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection='column'

                                sx={{
                                    width: 800 ,
                                    height: 350 ,
                                    backgroundColor: '#d9ebff',
                                    
                                    borderRadius: '32px',
                                    
                                   
                                }}

                            >
                                <Typography align="center" variant="h4">
                                    Connectez-vous a votre compte mail professionnel
                                 </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection='column'
                                    sx={{ m: 1 }}

                                >
                                    <Typography variant="h7" width = "480px"  align="center" marginBottom={"2rem"}>
                                        Liez votre boite mail professionnelle a MediaSide. Les mails seront envoyes depuis cette adresse

                                    </Typography>

                                </Box>
                                <Box
                                    sx={{ width: '75%' }}
                                >
                        <CssFormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Compte</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"                               
                                label="Compte"
                                defaultValue = ""
                                onChange={handleSelectChange}
                                
                            >
                                <MenuItem value="8">Google</MenuItem>
                                {/* <MenuItem value="Yahoo">Yahoo</MenuItem>
                                <MenuItem value="9">Compte SMTP/IMAP</MenuItem> */}
                            </Select>
                        </CssFormControl>
                                </Box>
                                <Button  variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem' , backgroundColor: "#73b5ff", width: "12rem", height: "2.7rem", borderRadius: "14px" }} onClick={handleNextButtonClick}> Suivant</Button>
                            </Box>




                        </Box>
                    </ThemeProvider>
  

    );
};





export default AccountSetupPages