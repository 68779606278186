import React, { useEffect,useContext  } from 'react'
import backgroundImage from '../../public/background_image_media_side.png';
import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { themeApp } from '../../theme_scene/theme';
import { BlueBox, RowCenterFlexBox, ConnectButton, UserReviewsContainer, ReviewsTitle, Divider, ReviewContainer, ReviewText, UserTitle, UserJob, IconBox, Title, Description, ServiceBox, RegisterButton, ColumnFlexBox, ServicesContainer, ButtonBox, TitleBox, LineBox, DescriptionBox } from '../../theme_scene/custom_component';
import SequenceScheduler from './SequenceScheduler';
import { FormContext } from '../MultiStepForm';


export default function EmailScheduler() {

    //import des variables d'environnement
    const {formData, setFormData, nextPage} = useContext(FormContext);

  
  
  return (
 
<ThemeProvider theme={themeApp}>
  <ColumnFlexBox alignItems={"center"} justifyContent={"center"} width={"100%"} textAlign={"center"}>
    <img src={backgroundImage} className="choice_background_image" alt="" />
    <Typography variant="blueTitle" width={"35rem"} marginTop="1rem">Votre séquence de mail</Typography>
    </ColumnFlexBox>
    <Box mt={"4rem"}>
    <SequenceScheduler formData={formData}/>

    </Box>
    

  
</ThemeProvider>


  )
}
