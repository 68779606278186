import { Link, Box, Button, styled, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';

export const SequenceBox = styled(Box)`
    background-color : #d9ebff;
    border-top-left-radius: 25px 25px;
    border-top-right-radius: 25px 25px;
    display: flex;
    flex-direction: column;
    width : 10rem;
    height : 7rem;
    justify-content : space-between;

`;

export const SequenceBoxTitle = styled(Typography)`
margin-left : 2rem;
margin-top : 0.8rem;
font-size : 0.7rem;
font-weight : bold;   
`;

export const SequenceBoxObject = styled(Box)`
margin-left : 2rem;
margin-top : 0.5rem;
font-size : 0.7rem;
`;

export const SequenceBoxABTitle = styled(Box)`
font-size : 0.7rem;
display : flex;
justify-content : center;
align-items : center;
background-color : #007AFF;
border-bottom-right-radius: 25px 25px;
border-bottom-left-radius: 25px 25px;
width : 10rem;
height : 1.3rem;
color : white;
`;
