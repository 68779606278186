import {Link, Box, Button, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme, styled } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { ClassNames } from "@emotion/react";
import backgroundImage from '../../public/background_image_media_side.png';
import React, { useContext } from "react";
import ApplicationContext from '../../context';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1565c0',
      },
      '&:hover fieldset': {
        borderColor: '#73b5ff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1565c0',
      },
    },
  });


const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer", 
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;", 
            display: "flex",
            justifyContent : "center",
            width: "18rem"
            

        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },        
    }
});

//Création du schéma de validation de Yup 
// const registerSchema = yup.object().shape({
//     firstname: yup.string().required("required"),
//     SideMail: yup.string().email("invalid email").required("required"),
//     Password: yup.string().required("required"),

// });



const registerSchema = yup.object().shape({
    firstname: yup.string(),
    SideMail: yup.string().email("invalid email"),
    Password: yup.string(),

});

const initialValuesRegister =  {   
    firstname: "",
    SideMail: "",
    Password: "",
    GoogleMail: [],
    ProMail: [],
    Campagnes: []
    
    };



const HomePage = () => {
    const location = useLocation();

    // Accéder à l'objet loggedUser depuis l'emplacement
    const loggedUser = location.state.profile.user;
    const firstname = loggedUser.firstname
    console.log(loggedUser)

    const navigate = useNavigate();

    var {account, setaccount} = useContext(ApplicationContext)
    
   

    console.log(setaccount)
    console.log(account)
    


    const register = async (values, onSubmitProps) => {
        console.log("register")
        // const savedUserResponse = await fetch("http://localhost:3001/auth/register", {
            const savedUserResponse = await fetch("https://papa-moderne.com/api/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        });

        const savedUser = await savedUserResponse.json();
        onSubmitProps.resetForm();
        console.log(savedUser)

        //Vérification que le compte a correctement été créée
        if (savedUser.firstname && savedUser.SideMail && savedUser.Password) {
            console.log("Création du compte réussie");
            console.log(savedUser.SideMail)
            const newMail = savedUser.SideMail
           
            setaccount(newMail)
            console.log(account)
            navigate("/initmail");    
            
            
           
            
           
          } else {
            console.log("Création du compte échouée");
          }

         
          

    }

    const handleFormSubmit = async (values, onSubmitProps) => {
        console.log("handleFormSubmit")

        // setaccount(1)
        // console.log(account)
    
        
        const FirstStepVerification = await register(values, onSubmitProps);

    };

    
    

    
    return (
        
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesRegister}
            validationSchema={registerSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    
        <img src = {backgroundImage} className="first_background_image" alt = ""/>
        <img src = {backgroundImage} className="second_background_image" alt = ""/>
        

        <ThemeProvider theme={theme}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection =  'column'

                sx={{
                    width: 700 ,
                    height: 500 ,
                    backgroundColor: '#d9ebff',
                    
                    borderRadius: '32px',
                    
                   
                }}

            >
                    <Typography variant="h4">
                        Bienvenue {firstname}
                    </Typography>
             
                        <Typography align="center" variant="h7">
                        Ecrivez votre mail 
                            
                        </Typography>
                   
                    <Box
                        sx={{ width: '75%', margin: '0.1rem' }}
                    >
                            <CssTextField
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            name="firstname"
                            error={
                            Boolean(touched.firstname) && Boolean(errors.firstname)
                            }
                            helperText={touched.firstname && errors.firstname}
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            label="Prénom"
                            
                            autoComplete="firstname"
                            autoFocus
                           
                            sx={{
                                input: {
                                    height : "200px"
                                  
                                //   background: "white",
                                //   border: 2,
                                //   borderColor : "#007aff",
                                //   borderRadius: "15px",
                                  
                                },
                                
                                
                              }}
                            
                        />
                    </Box>
                    


                    

                     <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem" }} >Envoyer</Button>
                   


            </Box>




            </Box>
                    </ThemeProvider>
                </form>
            )}
            
        </Formik>


     );
};

export default HomePage