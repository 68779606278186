import { Box, Button, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme, styled } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import { redirect, useNavigate } from "react-router-dom";
import backgroundImage from '../../public/background_image_media_side.png';
import ApplicationContext from '../../context';
import React, { useContext } from "react";
import { FormContext } from '../MultiStepForm';


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#1565c0',
        },
        '&:hover fieldset': {
            borderColor: '#73b5ff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#1565c0',
        },
    },
});


const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer",
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;",
            display: "flex",
            justifyContent: "center",
            width: "18rem"


        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },
    }
});


const SMTPSchema = yup.object().shape({   
    SideMail: yup.string().email("invalid email"),
    Password: yup.string(),
    Port: yup.number(),
    Adress : yup.string()


});





const SmtpSetup = () => {

    //import des variables d'environnement
    const {formData, setFormData, nextPage} = useContext(FormContext);
    const email = formData.UserMail;


    const {account, setaccount} = useContext(ApplicationContext)
    const SideMail = account
    console.log("changement")
    console.log(account)
    
    const initialValuesSMPTPSetup = {   
        SideMail :  email,
        host : "", 
        port: "",
        user: "",
        password: ""        
    };
    const navigate = useNavigate();

    const updateProMail = async (values, onSubmitProps) => {
        console.log("updateProMail")
        console.log(values)
        // const updateProAccount = await fetch("http://localhost:6001/auth/updateUserInfoPro", {
            const updateProAccount = await fetch("https://papa-moderne.com/api/updateUserInfoPro", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        });

        const isProMailAdded = await updateProAccount.json();
        onSubmitProps.resetForm();
        console.log(isProMailAdded) 
        if (isProMailAdded== "User info updated"){
            navigate("/home")
        }

    }

    const handleFormSubmit = async (values, onSubmitProps) => {
        console.log("handleFormSubmit")

        const updateProMailAccount = await updateProMail(values, onSubmitProps);
        
    };


    return (

        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesSMPTPSetup}
            validationSchema={SMTPSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>

                    <img src={backgroundImage} className="first_background_image" alt="" />
                    <img src={backgroundImage} className="second_background_image" alt="" />


                    <ThemeProvider theme={theme}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection='column'

                                sx={{
                                    width: 500,
                                    height: 600,
                                    backgroundColor: '#d9ebff',

                                    borderRadius: '32px',


                                }}

                            >
                                <Typography variant="h4" align="center" width={"30rem"}>
                                    Connectez-vous à votre compte mail professionnel 
                                </Typography>

                                <Typography align="center" variant="h7"  width={"30rem"}>
                                    Liez votre boîte mail professionnelle à MediaSide. Les mails seront envoyés depuis cette host
                                </Typography>




                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.user}
                                        name="user"
                                        error={
                                            Boolean(touched.user) && Boolean(errors.user)
                                        }
                                        helperText={touched.user && errors.user}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="user"
                                        label="Email d'envoi"

                                        autoComplete="user"
                                        autoFocus

                                     

                                    />
                                </Box>

                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"

                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}

                                        error={Boolean(touched.password) && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}

                                        sx={{
                                            input: {

                                                //   background: "white",
                                                //   border: 2,
                                                //   borderColor : "#007aff",
                                                //   borderRadius: "15px",


                                            },


                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        name="port"
                                        label="Port"                                       
                                        id="port"
                                        autoComplete="port"
                                        width = '50%'

                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.port}

                                        error={Boolean(touched.port) && Boolean(errors.port)}
                                        helperText={touched.port && errors.port}

                                        sx={{
                                            input: {

                                                //   background: "white",
                                                //   border: 2,
                                                //   borderColor : "#007aff",
                                                //   borderRadius: "15px",


                                            },


                                        }}
                                    />
                                <CssTextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        name="host"
                                        label="Adresse du serveur"                                       
                                        id="host"
                                        autoComplete="host"

                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.host}

                                        error={Boolean(touched.host) && Boolean(errors.host)}
                                        helperText={touched.host && errors.host}

                                        sx={{
                                            input: {

                                                //   background: "white",
                                                //   border: 2,
                                                //   borderColor : "#007aff",
                                                //   borderRadius: "15px",


                                            },


                                        }}
                                    />
                                </Box>

                                <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem" }} >Je me connecte</Button>
                                

                            </Box>




                        </Box>
                    </ThemeProvider>
                </form>
            )}

        </Formik>


    );
};

export default SmtpSetup