import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from './scenes/homePage';
import MailSetup from './scenes/MailSetup';
import RegisterPage from './scenes/RegisterPage';
import LoginPage from './scenes/LoginPage';
import AccountSetupPages from './scenes/AccountSetupPages';
import LandinPage from "./scenes/landingPage";
import ZoneChoice from "./scenes/ZoneChoice";
import SectorChoice from "./scenes/SectorChoice";
import React, { useEffect, useState, createContext } from "react";
import ApplicationContext from './context';
import SelectionNews from "./scenes/SelectionNews";
import PrestationChoice from "./scenes/PrestationPage";
import MultiStepForm from "./scenes/MultiStepForm";
import MediaChoice from "./scenes/MediaChoice";
import SequenceScheduler from "./scenes/EmailScheduler/SequenceScheduler";
import EmailScheduler from "./scenes/EmailScheduler/EmailScheduler";
import { PrivateRoute } from "./protected_routes";
import Dashboard from "./scenes/Dashboard";
import MultiStepNewCampaign from "./scenes/MultiStepNewCampaign";
import SequenceSchedulerVisualizer from "./scenes/EmailScheduler/SequenceSchedulerVisualizer";


// Créer un contexte pour stocker les données de l'utilisateur et suivre son état de connexion
// export const FormContextLogin = createContext({ account: "", setaccount: () => {}, userToken: "", setUserToken: () => {} });
export const FormContextLogin = createContext();


function App() {



  var [account, setaccount] = useState("");
  console.log("account", account)
  var [userToken, setUserToken] = useState("");




  return (



    <BrowserRouter>
      <FormContextLogin.Provider value={{ account, setaccount, userToken, setUserToken }}>
        {console.log("userToken", userToken)}


        <Routes>


          <Route exact path='/mail/:typeMail' element={<MailSetup />} />

          <Route path="/newcampaign" element={<MultiStepNewCampaign />} />
          {/* <Route path='/dashboard' element={<Dashboard/>} />  */}
          <Route path="/initmail" element={<AccountSetupPages />} />
          {/* <Route path="/register" element={<RegisterPage />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LandinPage />} />
          <Route path="/zoneChoice" element={<ZoneChoice />} />
          <Route path="/sectorChoice" element={<SectorChoice />} />
          <Route path="/selectionNews" element={<SelectionNews />} />
          <Route path="/prestationChoice" element={<PrestationChoice />} />
          <Route path="/multiStep" element={<MultiStepForm />} />
          <Route path="/mediaChoice" element={<MediaChoice />} />
          {/* <Route exact path='/dashboard' element={<PrivateRoute/>}>
               <Route path='/dashboard' element={             
              <Dashboard/>
              }/> 
                 
              </Route> */}
          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
          </Route>



        </Routes>
      </FormContextLogin.Provider>


    </BrowserRouter>


  );
}

export default App;
