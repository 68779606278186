import { Box, Button, TextField, makeStyles, Container, CssBaseline, Typography, useTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import { createTheme, styled } from '@mui/material/styles';
import { Formik } from "formik";
import HelpIcon from '@mui/icons-material/Help';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Link } from "@mui/material";
import * as yup from "yup";
import { redirect, useNavigate } from "react-router-dom";
import backgroundImage from '../../public/background_image_media_side.png';
import ApplicationContext from '../../context';
import React, { useContext, useState } from "react";
import { FormContext } from '../MultiStepForm';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#1565c0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#1565c0',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#1565c0',
        },
        '&:hover fieldset': {
            borderColor: '#73b5ff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#1565c0',
        },
    },
});


const theme = createTheme({
    typography: {
        body1: {
            color: '#212B27'
        },
        h4: {
            margin: "0.5rem",
            color: "#212B27",
            fontFamily: "revert-layer",
            fontWeight: "bold"

        },
        h7: {
            margin: "0.5rem",
            color: "#32403B",
            fontFamily: "sans-serif;",
            display: "flex",
            justifyContent: "center",
            width: "18rem"


        },
        button: {
            color: '#73b5ff'
        },
        p: {
            color: 'red'
        },
    }
});

//Création du schéma de validation de Yup 
// const registerSchema = yup.object().shape({
//     firstname: yup.string().required("required"),
//     SideMail: yup.string().email("invalid email").required("required"),
//     Password: yup.string().required("required"),

// });
const gmailUpdateSchema = yup.object().shape({
    GoogleMail: yup.string().email("invalid email"),
    Password: yup.string(),

});





const GmailSetup = () => {
    //Gérer l'alerte en cas de mauvais mail 
    const [showError, setShowError] = useState(false);

    //Gérer la pop up d'aide : 
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //import des variables d'environnement
    const { formData, setFormData, nextPage } = useContext(FormContext);
    const email = formData.UserMail;
    const initialValuesGmailAccount = {
        UserMail: email,
        gmail: "",
        gmailPassword: ""
    };
    const navigate = useNavigate();

    const updateGmail = async (values, onSubmitProps) => {
        console.log("updateGmail")
        console.log(values)
        // const updateGoogleAccount = await fetch("http://localhost:6001/auth/updateUserInfo", {
            const updateGoogleAccount = await fetch("https://papa-moderne.com/api/updateUserInfo", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        });
    
        if (updateGoogleAccount.status === 400) {
            setShowError(true);
            return;
        }
    
        const isGmailAdded = await updateGoogleAccount.json();
        onSubmitProps.resetForm();
        console.log(isGmailAdded)
        nextPage()
    }
    

    const handleFormSubmit = async (values, onSubmitProps) => {
        console.log("handleFormSubmit")

        const updateGAccount = await updateGmail(values, onSubmitProps);

    };


    return (

        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesGmailAccount}
            validationSchema={gmailUpdateSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>

                    <img src={backgroundImage} className="first_background_image" alt="" />
                    <img src={backgroundImage} className="second_background_image" alt="" />


                    <ThemeProvider theme={theme}>
    
                    {showError && 
  <Stack sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
    <Alert severity="error">Mot de passe ou email de connexion invalide</Alert>
  </Stack>
}

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection='column'

                                sx={{
                                    width: 500,
                                    height: 500,
                                    backgroundColor: '#d9ebff',

                                    borderRadius: '32px',


                                }}

                            >
                                <Typography variant="h4" align="center" width={"30rem"}>
                                    Connectez-vous à votre compte Google
                                </Typography>

                                <Typography align="center" variant="h7" width={"30rem"}>
                                    Liez votre boîte mail professionnelle à MediaSide. Les mails seront envoyés depuis cette adresse
                                </Typography>




                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.gmail}
                                        name="gmail"
                                        error={
                                            Boolean(touched.gmail) && Boolean(errors.gmail)
                                        }
                                        helperText={touched.gmail && errors.gmail}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="gmail"
                                        label="Email d'envoi"

                                        autoComplete="gmail"
                                        autoFocus



                                    />
                                </Box>

                                <Box
                                    sx={{ width: '75%', margin: '0.1rem' }}
                                >
                                    <CssTextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        name="gmailPassword"
                                        label="Mot de passe"
                                        type="password"
                                        id="gmailPassword"
                                        autoComplete="current-password"

                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.gmailPassword}

                                        error={Boolean(touched.gmailPassword) && Boolean(errors.gmailPassword)}
                                        helperText={touched.gmailPassword && errors.gmailPassword}

                                        sx={{
                                            input: {

                                                //   background: "white",
                                                //   border: 2,
                                                //   borderColor : "#007aff",
                                                //   borderRadius: "15px",


                                            },


                                        }}
                                    />
                                </Box>


                                <Button type="submit" variant="contained" sx={{ margin: '1rem 0rem 0rem 0rem', backgroundColor: "#73b5ff", width: "18rem", height: "2.7rem" }} >Je me connecte</Button>
                                <Button onClick={handleClickOpen} sx={{ textTransform: 'none' }}>
                                    Afficher les instructions de connexion à Gmail
                                </Button>
                                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                        Connexion Gmail
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <Typography gutterBottom>
                                            I. Activer la connexion IMAP
                                        </Typography>
                                        <DialogContentText>
                                            1. Allez sur votre compte Gmail, cliquez sur l'icône "Paramètres" en haut à droite, puis sur "Voir tous les paramètres".
                                            <br />
                                            2. Allez dans l'onglet "Transfert et POP/IMAP".
                                            <br />
                                            3. Dans "Accès IMAP", cochez "Activer IMAP", puis sauvegardez.
                                        </DialogContentText>
                                        <Typography gutterBottom>
                                            II. Activer la double authentification sur votre compte
                                        </Typography>
                                        <DialogContentText>
                                            1. Allez sur <Link href="https://myaccount.google.com/signinoptions/two-step-verification" target="_blank" rel="noopener">https://myaccount.google.com/signinoptions/two-step-verification</Link>.
                                            <br />
                                            2. Vérifiez que vous êtes bien sur le bon compte Google.
                                            <br />
                                            3. Activez la validation en deux étapes.
                                        </DialogContentText>
                                        <Typography gutterBottom>
                                            III (Nouveau) Dernière étape
                                        </Typography>
                                        <DialogContentText>
                                            1. Créez un mot de passe d’application ici : <Link href="https://myaccount.google.com/apppasswords" target="_blank" rel="noopener">https://myaccount.google.com/apppasswords</Link>.
                                            <br />
                                            2. Utilisez ce mot de passe d’application dans Emelia.
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>


                            </Box>





                        </Box>
                    </ThemeProvider>
                </form>
            )}

        </Formik>


    );
};

export default GmailSetup